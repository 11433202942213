.full-text-buttons {
  text-align: center;
}

.full-text-button {
  margin: 1px;
}

.badge {
  margin-right: 1px;
}