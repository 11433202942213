.citation__link {
  font-style: normal;
  color: #000000;
}

.citation__link:hover {
  cursor: pointer;
}

.csl-left-margin {
  display: none;
}
