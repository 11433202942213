.roleLabel {
  font-style: italic;
  color: #757575;
  margin-bottom: 10px;
}

.corresponding {
  color: #757575;
}

div.roleLabel.col {
  padding-left:  0px;
}