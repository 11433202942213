div.col.scientificField {
  padding-left:  0px;
  padding-bottom: 16px;
}

.scientificField > ul {
  margin-bottom: 0px;
}

.scientificField > ul > li {
  padding-left: 15px;
}