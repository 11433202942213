.cover {
  text-align: center;
}

.page-count {
  margin-top: 1em;
}

.page-count span {
  font-weight: bold;
}