.contributor-full-name {
  text-decoration: underline dotted;
  font-style: normal;
  color: #000000;
}

.contributor-full-name:hover {
  cursor: pointer;
}

.contributors > div > span {
  margin-right: 10px;
}

.contributor-button-container {
  margin-left: 0.75rem;
  display: inline-block;
}

.contributors sup {
  color: #000000;
}
