
.journal-option {
    margin-left: 20px;
}

.journal-option > .journal-title {
    font-style: italic;
}

.journal-option > .issn {
    margin-left: 10px;
    font-size: smaller;
    color: gray;
}
