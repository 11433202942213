@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,300;0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap');

/* Bootstrap mixins */

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

/* Variables */

$font-PTSerif: "PT Serif", serif;
$font-Roboto: "Roboto", "Fira Sans", sans-serif;
$font-Hammersmith: "Hammersmith One", sans-serif;
$font-size-default: 16px;

$grey-background-color: #f0efef;
$grey-border-color: #dddbda;

$black-link-color: #000;
$black-link-hover-color: #4F4F4F;

$blue-link-color: #005380;
$blue-link-hover-color: #000;

$blue-link2-color: #0053C0; // 7:1 contrast with white, 3:1 contrast with black (can be displayed without underline when on white background)
$blue-link2-hover-color: #000;

$white-link-color:#FFF;
$white-link-background-color: $blue-link-color;
$white-link-border-color: #fff;
$white-link-outline-color: #000;

$red-button-color: #fff;
$red-button-background-color: #A72929;
$red-button-border-color: #A72929;
$red-button-hover-color: #A72929;
$red-button-hover-background-color: #fff;
$red-button-hover-border-color: #A72929;

$highlight-background-color: #015b5b;

$logo-gradient-color-top: #662383;
$logo-gradient-color-bottom: #0377bd;
$logo-gradient-vertical: linear-gradient(180deg, $logo-gradient-color-top 0%, $logo-gradient-color-bottom 100%);


@mixin card-header {
  background: none;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 1px solid $grey-border-color;
}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
}


html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-Roboto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

div, main {
  &.container{
    margin-top: 20px;
  }
}

.container.container-without-cols {
  @include media-breakpoint-down(xs) {
    .row {
      padding: 0 1rem;
    }
  }
}

ul {
  padding-left: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#main-container {
  flex: 1 1 auto;
}

.hidden {
  display: none;
}

.preserve-whitespace {
  white-space: pre-wrap;
}

.cover-image {
  max-height: 200px;
  max-width: 200px
}

.spinner-container {
  text-align: center;
}

.publishing-company-logo {
  max-height: 200px;
  max-width: 200px;
}

.pagination-container {
  justify-content: center;
}

.clamped {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

a {
  color: $blue-link-color;

  &:hover, &:active, &:focus {
    color: $blue-link-hover-color;
  }
}
.external-link-icon {
  margin-left: 3px;
}

hr {
  border-color: $grey-border-color;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.5rem;
}

.space-apart {
  margin: -0.25rem 0;

  > * {
    margin: 0.25rem 0;
  }

  > *:not(:last-child) {
    margin-right: 1rem;
  }
}

.space-between-list-elements {
  div + h3 {
    margin-top: 0.5em;
  }
}

.md-row, .sm-row {
  width: 100%;

  > div {
    display: block;
  }
}

.md-row + .md-row {
  margin-top: -1rem;
}

.break-word {
  word-break: break-word;
}

.card mjx-math {
  white-space: normal;
}

@include media-breakpoint-up(md) {
  .md-row > div {
    display: inline-block;
  }
}

@include media-breakpoint-up(sm) {
  .sm-row {
    width: auto;

    > div {
      display: inline-block;
    }
  }
}

/* Fix missing top border in tiled lists */
.list-group-item + .list-group-item {
  border-top-width: 1px;
}

/* Buttons */

@mixin btn-common-styling {
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 1.25px;
}

.btn:focus {
  box-shadow: 0 0 0 .2rem rgba(0,123,255,.8); // bootstrap override - higher opacity
}
.btn-light:focus {
  box-shadow: 0 0 0 .2rem rgba(216,217,219,.8); // bootstrap override - higher opacity
}

.btn-primary:not(:disabled):not(.disabled) {
  @include btn-common-styling;
  color: $red-button-color;
  background-color: $red-button-background-color;
  border-color: $red-button-border-color;

  &:hover, &:active, &:focus {
    color: $red-button-hover-color;
    background-color: $red-button-hover-background-color;
    border-color: $red-button-hover-border-color;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled) {
  @include btn-common-styling;
  color: $red-button-hover-color;
  background-color: $red-button-hover-background-color;
  border-color: $red-button-hover-border-color;

  &:hover, &:active, &:focus {
    color: $red-button-color;
    background-color: $red-button-background-color;
    border-color: $red-button-border-color;
  }
}

.btn-light {
  border-color: #dddbda;

  &:hover, &:active, &:focus {
    border-color: #000;
  }
}

.full-text-button svg {
  margin-right: 0.2em
}

.accessibility-request-icon {
  margin-right: 0.2em;
  margin-left: 0.2em;
}

.btn[disabled] {
  cursor: not-allowed;
}

/* Forms */

.form-control:focus {
  border-color: rgb(0,123,255);
}

/* Main logo */

@mixin main-logo-dimensions ($step, $font) {
  .main-logo-image {
    margin-right: calc(#{$step} * 2);
    height: calc(#{$step} * 10);
  }

  .main-logo-text {
    height: calc(#{$step} * 10);
    font-size: $font;
    line-height: $font;
    letter-spacing: calc(#{$font} / 40);
  }
}

@include main-logo-dimensions(6.4px, 20px);

.main-logo {
  @include no-select;

  padding: 5px 0;
}

.main-logo-image {
  float: left;
}

.main-logo-text {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;

  font-family: $font-Hammersmith;
  text-align: left;
}

@include media-breakpoint-down(sm) {
  @include main-logo-dimensions(4.8px, 20px);
}

/* Header */

@mixin blue-uppercase-btn() {
  margin: 0.125em 0;
  padding: .5em 1em .25em 1em;
  background-color: #0054AD;
  border-color:#0054AD;
  border-radius: 4px;
  color: #FFF;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.25px;

  &:hover, &:active, &:focus {
    background-color: rgba(255,255,255,0);
    color: #0054AD;
  }
}

nav .container,
footer .container {
  margin: 0 auto;
  padding: 0;
}

.navbar {
  padding: 9px 15px !important;
}

.navbar-brand {
  padding: 0;
}

.navbar-light {
  background: #FFF;

  .navbar-nav {
    .nav-link {
      color: #000;

      &.mr-3 {
        margin-right: 0 !important;
      }

      &:hover, &:active, &:focus {
        color: #545454;
      }
    }

    .btn {
      line-height: 1.5em;
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar-light .navbar-nav .nav-link.mr-3 {
    margin-right: 16px !important;
  }
}

@include media-breakpoint-down(sm) {
  .navbar-brand {
    margin-right: 0;
  }
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding: calc(#{$font-size-default} / 2) $font-size-default;
}

@include media-breakpoint-between(md, lg) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: calc(#{$font-size-default} / 2);
  }
}

.btn-group.navbar-nav {
  .header-link-container .btn {
    border-radius: 0;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: $font-size-default;
  }

  .btn.btn-sm {
    @include blue-uppercase-btn();
    padding: 0.5em 1em;
  }
}

#basic-navbar-nav.navbar-collapse {
  position: relative;
  top: 3px;
  flex-grow: unset;
}

.header-dropdown-btn {
  padding: .25em .5em;

  button {
    position: relative;
    top: -1px;
    padding: .25em .5em;
    font-weight: 500;
    border-radius: 0;
    color: $black-link-color;

    &:hover, &:active, &:focus {
      text-decoration: none;
      color: $black-link-hover-color;
    }
  }

  .dropdown-menu {
    right: 0;
    left: auto;
  }

  .dropdown-item.btn {
    margin: 0.125em 0.5em !important;
    width: auto;
    text-align: center;

    &.btn-sm {
      border-width: 1px;
    }

    &.active:not(.btn-sm) {
      background: none;
    }
  }

  .btn.btn-sm.btn-primary.dropdown-toggle {
    margin-top: -1px;
  }

  .btn-primary.nav-link {
    font-size: calc(#{$font-size-default} * 0.875);
  }
}

@include media-breakpoint-between(md, lg) {
  .header-dropdown-btn {
    padding: 0.25em 0;
  }
}

@include media-breakpoint-down(md) {
  .header-dropdown-btn {
    .dropdown-menu {
      margin: 0.5em -2em !important;
    }

    .btn.btn-sm.btn-primary.dropdown-toggle {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.dropdown-toggle.btn {
  font-size: $font-size-default;
}

.change-language-flag {
  position: relative;
  bottom: 2px;
  margin-right: 4px;
}

@include media-breakpoint-up(lg) {
  .header-dropdown-btn {
    margin-left: 1rem;
  }
}

@include media-breakpoint-between(md, lg) {
  #basic-navbar-nav {
    margin-left: -15px;
    padding: 0;

    .mr-3 {
      margin-right: 0 !important;
    }
  }
}

/* Skip links */

.skip-links {
  position:absolute;
  top:-1000px;
  width:100%;
  z-index:1000;


  > ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;

    > li {
      position:absolute;
      left: 50%;

      > a {
        position: relative;
        left: -50%;

        display:block;
        text-align:center;
        padding: 0.5em 3em;
        color: $white-link-color;
        background-color: $white-link-background-color;
        border: solid $white-link-border-color 2px;

        &:focus {
          top:1000px;
          text-decoration: underline;
          outline-color: $white-link-outline-color;
        }
      }
    }
  }
}

/* Safe mail */

div.safe-mail {
    display: inline;
}

/* Footer */

footer {
  margin-top: 1rem;
  padding: 6px 15px;
  width: 100%;
  border-top: 1px solid $grey-border-color;
  text-align: center;

  .nav-link {
    padding: .5em 1em;
    font-weight: 500;
    border-radius: 0;
    color: $black-link-color;

    &:hover, &:active, &:focus {
      color: $black-link-hover-color;
    }
  }

  .nav .mr-3 {
    padding: .5rem 1rem;
  }
}

.footer-logos {
  .row {
    width: calc(100% + 30px);
  }

  img:not(.main-logo-image) {
    max-height: 65px;
    max-width: calc(100vw - 1rem - 30px);
    margin: 0.5625rem;
  }

  .top-row img {
    max-height: 41px;
  }

  .main-logo {
    padding: 9px 8px 8px;
  }

  @include main-logo-dimensions(4.1px, 14px);

  img.logo-fe {
    max-height: 85px;
    margin: -0.25rem 0.5625rem;
  }
}

.footer-img-container {
  margin: 1rem 0;
}

footer .footer-links.row {
  border-top: 1px solid $grey-border-color;
  padding: 11px 0 5px;
  font-size: $font-size-default;
  width: 100%;

  a, span {
    white-space: nowrap;
    font-size: 0.9em;
  }

  // smaller gutter from standard bootstrap 15px
  margin-left: -5px;
  margin-right: -5px;

  > div {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.copyright-text {
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  span {
    padding: 0 1em;
  }
}

/* Cookies banner */

.cookies-banner {
  border-bottom: 1px solid $grey-border-color;
  margin: 0 -15px;
  width: calc(100% + 30px);
  text-align: center;
  padding: 0 1rem 0.25rem 1rem;
}

.cookies-banner-learn-more {
  display: inline-block;
  margin-left: 0.5rem;
  text-decoration: underline;
  white-space: nowrap;
}

#cookiesBannerDismissButton {
  margin-left: 1rem;
  margin-bottom: 2px;
  vertical-align: middle;
}

@include media-breakpoint-down(sm) {
  .cookies-banner {
    padding: 0.5rem 0.5rem 0.25rem 0.5rem;
  }
}

/* Breadcrumb */

.breadcrumb-container {
  border-bottom: 1px solid $grey-border-color;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: $blue-link-color;

  a {
    color: $blue-link-color;

    &:hover, &:active, &:focus {
      color: #000;
    }
  }

  .container {
    margin-top: 0.6875rem;
    padding: 0;
  }

  .breadcrumb {
    margin-bottom: 0.8125rem;
    padding: 0;
    background: none;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "＞";
}

@include media-breakpoint-down(xs) {
  .breadcrumb-container {
    padding: 0 1rem;
  }
}

/* WBN Access icon */

.wbn-icon-outer-container {
  display: inline-block;
}

.wbn-icon-container {
  border: 1px solid $logo-gradient-color-top;
  border-radius: 100%;
  background: $logo-gradient-vertical;
  color: #fff;

  width: 2rem;
  height: 2rem;
  display: flex;
  font-size: 1.25rem;
  place-content: stretch center;
  align-items: center;
  cursor: help;
}
.tabbed-details-name-header-container,
.search-result-download-licence-container,
.publication-download-licence-container,
.publication-kind-and-type {
  .wbn-icon-outer-container {
    margin-left: 0.25rem;
    float: right;
  }
}

.list-group-item:not(.publication-chapters-list-item), .card.wbn-access-restricted {
  .wbn-icon-outer-container {
    position: absolute;
    bottom: .6875rem;
  }
}

.list-group-item  {
  &.wbn-access-restricted {
    padding-right: 3.5rem;
  }

  .wbn-icon-outer-container {
    right: 1.25rem;
  }
}

.card {
  &.wbn-access-restricted {
    padding-right: 2.8125rem;
  }

  .wbn-icon-outer-container {
    right: .6875rem;
  }
}

.tabbed-details-name-header-container .wbn-icon-outer-container {
  margin-top: 0.25rem;
}

/* Main page */

@mixin search-input-responsive-fullwidth {
  & > *, button {
    border-radius: 0;
    width: 100%;
  }

  .input-group-append {
    margin-left: 0;
  }
}


@include media-breakpoint-down(sm) {
  .jumbotron {
    padding-bottom: 3rem;
  }
}

.jumbotron {
  margin-bottom: 0;
  position: relative;
  background-color: #022;
  border-radius: 0;

  .wbn-restricted .simple-search-header {
    max-width: 68%;
  }

  h1, .front-page-stats {
    color: #fff;
  }

  h1 {
    letter-spacing: 1.5px;
  }

  .simple-search-subtitle {
    margin-bottom: 1rem;
    font-weight: 300;
    letter-spacing: 0.6px;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1.2;
  }

  @include media-breakpoint-down(lg) {
    .front-page-stats {
      margin-top: 15px;
      text-align: left !important;
    }
  }

  .front-page-stats {
    text-align: right;

    .front-page-stat {
      margin-bottom: 1rem;
      transition: opacity 1s;

      &.hidden {
        opacity: 0;
        transition: opacity 1s;
        pointer-events: none;
      }
    }

    .fade-in {
      animation: fadeInLoad 1s;
    }
    @keyframes fadeInLoad {
      from {
          opacity: 0;
      }
      to {
          opacity: 1;
      }
    }

    .stats-header {
      display: none;
      padding: 0 15px;
      position: relative;
      align-items: end;
      justify-content: end;
      text-align: right;
      font-size: 24px;
      width: 100%;

      svg {
        position: absolute;
        bottom: 6px;
        right: -10px;
        font-size: 16px;
      }
    }

    .stats-number {
      font-size: 1.75rem;
      font-weight: bold;
      line-height: 1.2;
    }

    .stats-articles-hidden {
      visibility: hidden;
    }

  }

  .wbn-restricted .front-page-stats .stats-header {
    display: flex;
    padding: 0 15px 0 0;
  }

  input, button {
    &:focus{
      box-shadow: 0 0 0 .2rem rgb(0,123,255);
    }
  }

  .col > a {
    color: #fff;
    text-decoration: underline;

    &:hover, &:active, &:focus {
      color: #f5f5f5;
    }
  }

  .input-group {
    input {
      border: none;
    }

    .dropdown {
      letter-spacing: 0.38px;
    }

    .btn-outline-secondary {
      font-weight: 500;
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;

      &:hover, &:active, &:focus {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
      }
      &:focus {
        box-shadow: 0 0 0 .2rem rgb(108,117,125);
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .jumbotron {
    .wbn-restricted .simple-search-header {
      max-width: 100%;
    }

    .front-page-stats .stats-header,
    .wbn-restricted .front-page-stats .stats-header {
      margin-left: 15px;
      margin-right: 15px;
      height: 45px;
      width: 100%;
      text-align: left;
      justify-content: start;

      svg {
        position: static;
        margin-left: 6px;
        margin-bottom: 6px;
        font-size: 16px;
      }
    }
  }
}

.main-page-categories {
  margin-top: 0.75rem;
  margin-bottom: calc(-1rem - 1px);

  .container {
    margin-top: 0;
    padding: 0;

    display: flex;
    align-items: flex-end;
  }

  @include  media-breakpoint-down(lg) {
    .container {
      justify-content: center;
      flex-wrap: wrap;

      a.btn {
        min-width: 33%;
        max-width: 33%;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .container {
      a.btn {
        min-width: 50%;
        max-width: 50%;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    .container {
      a.btn {
        min-width: 100%;
        max-width: 100%;
      }
    }
  }

  a.btn {
    border-bottom: 5px solid rgba(0,0,0,0);
    border-radius: 0;
    color: $black-link-color;
    font-size: $font-size-default;

    &:active, &:hover, &:focus {
      text-decoration: none;
      color: $black-link-hover-color;
      border-bottom: 5px solid $black-link-hover-color;
    }

    &[aria-current="page"] {
      font-weight: bold;
      border-bottom: 5px solid $red-button-background-color;
    }
  }
}

@include media-breakpoint-down(sm) {
  .main-page-categories .container {
    padding: 0 15px;
  }
}

.main-page-section.container {
  margin-top: 1rem;
  margin-bottom: 1rem;

  h2.margin {
    margin-top: 2rem;
  }

  a {
    color: $black-link-color;

    &:hover, &:active, &:focus {
      color: $black-link-hover-color;
    }
  }

  a.card-block-link {
    display: block;

    &:hover, &:active, &:focus {
      z-index: 1;
      color: $black-link-color;
      text-decoration: none;

      .card {
        background-color: #f8f9fa;
      }
    }

    h4 {
      margin-bottom: 0;
    }
  }

  .card-contributors {
    margin-top: 0.5rem;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .card-container {
    margin-top: -1rem;
  }

  .no-elements-info {
    margin: 1rem 0;
  }

  .loading-failed-info {
    margin: 1rem 0;
  }

  .main-page-section-column-header {
    border-bottom: 1px solid $grey-border-color;

    h3 {
      display: inline-block;
      font-weight: 300;
    }

    .main-page-section-show-all {
      position: relative;
      top: 0.125rem;
      right: -.75rem;
      text-align: right;

      a {
        text-decoration: underline;
      }
    }
  }

  .card {
    margin-top: 1rem;
    border-radius: 0;

    h3 {
      font-size: 1.875rem;
      font-family: $font-PTSerif;
      letter-spacing: 0.6px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .main-page-section-fullwidth {
    margin-top: 1rem;

    .row > * {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  ul.main-page-section-side-links {
    margin-top: 1rem;
    padding-left: 0;

    a.btn {
      position: relative;
      left: -.75rem;
      font-weight: 500;
      color: $blue-link-color;

      &:active, &:hover, &:focus {
        color: $blue-link-hover-color;
      }
    }
  }

  @include media-breakpoint-down(lg) {
   .margin-top-lt-lg {
     margin-top: 1rem;
   }
  }
}

.main-page-publishers {
  margin-bottom: -1rem;
  background: $grey-background-color;
  border-top: 1px solid $grey-border-color;

  a {
    color: $black-link-color;

    &.btn-link {
      text-decoration: underline;
    }

    &:hover, &:active, &:focus {
      color: $black-link-hover-color;
    }
  }

  .btn {
    font-weight: 500;
    letter-spacing: 0.6px;
  }

  .main-page-publishers-header {
    border-bottom: 1px solid $grey-border-color;

    .btn {
      letter-spacing: 0.38px;
    }
  }

  .btn-primary:not(:disabled):not(.disabled) {
    @include blue-uppercase-btn();
    margin: 1rem 0;
  }

  .btn-lg {
    font-size: 1.25rem;
  }

  .main-page-publishers-links-container {
    margin: 0.5rem 0 1.5rem 0;

    .btn-lg {
      margin-left: -1rem;
      width: 100%;
      text-align: left;
    }
  }

  .main-page-publishers-button-container {
    text-align: right;
  }

  @include media-breakpoint-down(md) {
    .btn-primary {
      margin: 0 0 1rem 0;
    }

    .main-page-publishers-button-container {
      text-align: center;
    }
  }

}

@include media-breakpoint-up(sm) {
  .jumbotron {
    padding: 3rem 2rem;

    > div.container {
      margin-top: 0;
    }
  }
}

.jumbotron-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &:focus {
    outline: 4px dotted #000;
  }
  pointer-events: none;
}
.video-controls {
  position: absolute;
  bottom: 1em;
  right: 1em;

  button {
    color: white;
    border: solid white 1px;
    background-color: rgba(0, 0, 0, 0.1);

    &:hover, &:active, &:focus {
      background-color: rgba(0, 0, 0, 1);
    }
  }
}
.tooltip.in-dark-background-tooltip {
  .tooltip-inner {
    border: 1px solid #FFFFFF;
  }

  .arrow::before {
    border-top-color:  #FFFFFF;
  }
}


/* Advanced search form */

.advanced-search-panel {
  .card {
    border: 0;
  }

  .card-header {
    padding: 0;
    background-color: transparent;
    border: 0;

    &.btn-link {
      color: $blue-link-color;

      &:hover, &:active, &:focus {
        color: #000;
      }
    }

    .btn.advanced-search-toggle {
      color: $blue-link2-color;

      &:hover, &:active, &:focus {
        color: $blue-link2-hover-color;
      }
      &:focus {
        box-shadow: unset;
      }

      svg {
        margin-left: 0.5em;
      }
    }
  }

  label:not(.text-normalcase) {
    text-transform: uppercase;
  }

  .form-check {
    display: inline-block;

    + label {
      position: relative;
      bottom: 1px;
      margin-left: 0.5rem;
    }
  }
}

.advanced-search {
  .input-group {
    .btn-outline-secondary {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;

      &:hover, &:active, &:focus {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5;
      }
    }
  }
}

.search-form-multiple-inputs-line > div[class*="col-sm-"] {
  > label, > div {
    &[class*="col-sm-"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

#scope-dropdown {
  border: 1px solid $grey-border-color;
}

.form-row div[class$="multiValue"] {
  background: $blue-link-color;
  border-radius: 1rem;

  > div:first-child {
    padding: 0.1875rem 0.1875rem 0.1875rem 0.625rem;
    font-size: 0.875rem;
    letter-spacing: 0.25px;
    color: #fff;
    white-space: break-spaces;

    + div {
      border-radius: 1rem;
      color: #fff;
    }
  }
}

.search-form-submit {
  text-align: right;
}

@include media-breakpoint-up(lg) {
  .advanced-search .search-form {
    z-index: 2;
    max-width: 70%;
  }

  .advanced-search-panel.accordion {
    position: relative;
    top: -3.375rem;
    margin-bottom: -1.8125rem;
  }

  .advanced-search-toggle {
    max-width: 30%;
  }
}

@include media-breakpoint-up(sm) {
  .search-form-multiple-inputs-line > div[class*="col-sm-"] {
    &:first-child {
      padding-right: 25px;
    }

    + div[class*="col-sm-"] {
      padding-left: 25px;
    }
  }
}

@include media-breakpoint-down(xs) {
  .advanced-search .search-form,
  .jumbotron .search-form {
    @include search-input-responsive-fullwidth;
  }

  .search-form-clear, .search-form-submit {
    text-align: center;

    .btn {
      min-width: 50%;
    }
  }

  .search-form-submit {
    margin-top: 1rem;
  }

  .advanced-search .card-body {
    padding: 1rem 0;
  }
}

/* Search results */

.search-results {
  margin-bottom: -1rem;
  border-top: 1px solid $grey-border-color;
  background: $grey-background-color;

  h1 {
    font-size: 1.5rem;
  }
}

.search-dropdown-filters {
  label + div {
    margin-left: 0.8125rem;
    display: inline-block;
  }

  .page-size-select div[class$="control"] > div:first-child {
    padding-right: 2rem;
  }

  .sort-select div[class$="control"] > div:first-child {
    padding-right: 10rem;
  }
}

.search-dropdown-filter-container + .search-dropdown-filter-container {
  margin-left: 1rem;
}

.search-result-list-item {
  border-color: $grey-border-color;
  border-radius: 0;
  margin-top: 15px;
  margin-bottom: 15px;

  .container, div[class^="col-"] {
    padding-left: 1.1875rem;
    padding-right: 1.1875rem;
  }

  .row {
    margin-left: -1.1875rem;
    margin-right: -1.1875rem;
  }

  .card-body {
    padding: 0;
  }

  h2 {
    font-family: $font-PTSerif;
    font-size: 1.5rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  h3, .publication-kind {
    padding-bottom: 0.25rem;
    margin-bottom: .5rem;
    line-height: 1.2;
    margin-top: 0;
    @include card-header;
  }

  .scientific-field-link {
    text-decoration: underline;
  }
}

.search-result-list-item.access-restricted {
  background-color: $grey-border-color;
}

a.search-result-title-link {
  color: $black-link-color;
  text-decoration: underline;

  &:hover, &:active, &:focus {
    color: $black-link-hover-color;
  }
}


.search-result-contributor-list a {
  margin-right: 0.375rem;
  color: #6C6C6C;
  text-decoration: underline;

  &:hover, &:active, &:focus {
    color: #000;
  }

  + a {
    margin-left: 0.375rem;
  }
}

@mixin download-licence-container() {
  margin-top: -0.5rem;
  padding: 0.375rem 0.5rem;
  background: #f5f5f5;
  min-height: 2.5em;
  margin-bottom: 1rem;

  .btn {
    box-shadow: 0px 2px 2px #00000024;
  }
}

.search-result-download-licence-container {
  @include download-licence-container;
}

.search-result-download-label,
.publication-details-download-label {
  margin-right: 0.5rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .5px;
  position: relative;
  top: 1px;
}

.search-result-sidebar {
  font-size: 1rem;

  > div {
    margin-bottom: 15px;
  }
}

.search-result-journal {
  font-family: $font-PTSerif;
  font-weight: bold;
}

.search-result-issue {
  font-family: $font-PTSerif;
}

.search-result-publishing-company {
  font-family: $font-Roboto;
  font-weight: bold;
}

img.search-result-cover-thumbnail {
  max-height: 150px;
  max-width: 100%;

  &.custom-thumbnail {
    border: 1px solid $grey-border-color;
  }
}

.with-highlighting > em {
  padding: 0 0.333em;
  font-style: normal;
  color: #fff;
  background-color: $highlight-background-color;
}
a .with-highlighting > em {
  text-decoration: underline;
}

@include media-breakpoint-up(lg) {
  .search-dropdown-filter-container {
    display: inline-block;
  }
}

.page-item {

  .page-link {
    border: none;
    background-color: transparent;
    color: $blue-link2-color;
    font-weight: 700;
    font-size: 1em;
    padding: .15rem .625rem;

    &:hover, &:active, &:focus {
      color: $blue-link2-hover-color;
    }
    &:hover {
      border-bottom: 2px solid black;
    }
  }

  &.active .page-link {
    color: black;
    background-color: unset;
    border-bottom: 2px solid #A72929;
  }

  &:first-child .page-link {
    border-radius: 0;
  }
  &:last-child .page-link {
    border-radius: 0;
  }

  &.pagination-icon {
    .page-link {
      padding: .15rem .4375rem;
    }

    svg {
      position: relative;
      top: -1px;
    }
  }
}

.pagination-search {
  background: #f0efef;
  margin-bottom: -16px;
  padding-top: 1px;
}

/* Article / journal / book details screen */

@mixin nav-tabs {
  a.nav-link {
    padding: 0 0.3125rem;
    border: none;
    border-radius: 0;
    line-height: 1.25;

    &:hover, &:active, &:focus {
      border: none;
    }

    &.active {
      border: none;
      color: $black-link-color;
      border-bottom: 4px solid $red-button-border-color;

      &:hover, &:active, &:focus {
        color: $black-link-hover-color;
        border-bottom: 4px solid $red-button-border-color;
      }
    }
  }
}

.section {
  margin: 2px;
  width: 100%;
}

.publication-details-container {
  border: none;
  margin-top: 20px;

  .section {
    margin: 0;
    border: none;
  }

  .card-body {
    padding: 0;
  }

  .publication-kind-and-type {
    display: block;
    margin-bottom: 0.5rem;
    padding-bottom: 0.25rem;
    @include card-header;
    border-bottom: none;

    .publication-type {
      margin-left: 5px;
      font-weight: 400;
    }
  }

  h2 {
    margin-top: 0.8125rem;
    padding-bottom: 0.25rem;
    @include card-header;
  }

  h1 {
    font-family: $font-PTSerif;
    font-size: 1.5rem;
  }

  .header-with-tabs {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dddbda;
    margin-top: 0.8125rem;
    margin-bottom: .5rem;

    h2 {
      border-bottom: none;
      margin-top: 0;
      margin-bottom: 0;
    }

    .nav-tabs {
      display: flex;
      justify-content: flex-end;
      position: unset;
      top: unset;
      margin: 0;

    }
  }

  .nav-tabs {
    @include nav-tabs;

    display: block;
    position: relative;
    top: calc(-2.075rem + 1px);
    margin-right: 0;

    a.nav-link {
      float: right;
      font-weight: bold;
      color: $blue-link-color;

      &:hover, &:active, &:focus {
        color: $blue-link-hover-color;
      }

      &.active {
        background: none;
      }

      + a.nav-link {
        margin-right: 0.5rem;
      }
    }
  }

  .badge.badge-pill.badge-light {
    color: $blue-link-color;
    font-size: 1rem;
  }
}

.publication-details-sidebar {
  background: #F5F5F5;

  .sidebar-license {
    margin-top: 1rem;
  }

  .image-section {

    .issue-journal-title {
      margin: 1rem 0 0 0;
      letter-spacing: 0.23px;
    }

    img {
      max-height: 262px;
      max-width: 100%;
    }

    .image-section a {
      font-family: $font-PTSerif;
    }
  }

  .image-section a,
  a.publication-details-sidebar-link {
    color: $black-link-color;

    &:hover, &:active, &:focus {
      color: $black-link-hover-color;
    }
  }

  .card {
    border: none;
    background: none;

    .card-body {
      padding: 0.5rem 1.25rem;
    }
  }

  a {
    text-decoration: underline;
  }
}

.publication-details-conference-name {
  font-weight: bold;
}

.book-details-chapters.list-group,
.book-details-bookparts.list-group {
  padding: 0.5em 0;
}

.book-details-bookparts {
  > .list-group-item {
    padding: 0;
    border: 0;

    > h3 {
      padding: 0 0.5rem;
      font-family: $font-PTSerif;
    }

    &:not(:first-child) > h3 {
      margin-top: 1.5rem;
    }
  }

  em {
    padding: 0.5rem;
  }
}

.publication-chapters-list-item {
  border: $grey-border-color solid 1px;
  padding: 8px 10px;

  .publication-download-licence-container {
    margin-bottom: 0.5rem;
  }

  a.chapter-link {
    position: relative;
    width: 100%;
    display: inline-block;
    text-decoration: underline;
    color: $black-link-color;

    &:hover, &:active, &:focus {
      color: $black-link-hover-color;
    }

    h3, h4 {
      padding: 0 0.5rem;
      font-size: 1.5rem;
      font-family: $font-PTSerif;
    }
  }

  &.list-group-item {
    border-radius: 0;
  }

  .publications-list-authors {
    margin: 0;
    padding: 0 0.5rem 0.125rem;
    font-weight: 500;
    display: inline;

    li {
      display: inline;

      + li {
        margin-left: 1em;
      }
    }
  }

  .page-range {
      margin: 0.25rem 0.5rem;
  }
}

.publication-chapters-list-item + .publication-chapters-list-item {
  margin-top: -1px;
}

.publication-download-licence-container {
  @include download-licence-container;

  .license-link, > .license-image {
    float: right;
  }
}

.roleLabel {
  font-weight: 500;
  color: #000;
  text-transform: uppercase;
  font-style: normal;
}

.contributor-full-name {
  font-weight: 500;
  color: $blue-link-color;
  text-transform: none;
  padding: 0;
  vertical-align: baseline;
  text-decoration: underline;

  &:hover, &:active, &:focus {
    color: $blue-link-hover-color;
  }
  &:focus {
    outline: dotted black 1px;
  }

  &.btn {
    user-select: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto;
  }
}

.contributors {
  .annotations {
    white-space: nowrap;
  }
}

.orcid-link {
  text-decoration: underline;
}

@include media-breakpoint-up(sm) {
  .contributors > span {
    white-space: nowrap;
  }
}

%details-side-panel-button {
  padding: 0.4rem 0.8rem;
  border: 1px solid #0000001F;
  background: #fff;
  color: $blue-link-color;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-weight: 500;

  &:hover, &:active, &:focus {
    background: $grey-background-color;
    color: $blue-link-hover-color;
    border-color: #000;
  }
}

.citation__link,
.share__link {
  @extend %details-side-panel-button;
}

.export__link {
  @extend %details-side-panel-button;
  text-decoration: none !important;
}

.section.card.preview-mode-warning {
  margin: 0;
  border: 0;
  color: $red-button-border-color;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;

  > .card-body {
    padding: 0;
  }
}

.tabbed-details-name-container .section.card.preview-mode-warning,
.tabbed-details-name-header-container .section.card.preview-mode-warning {
  margin-top: 1rem;

  p {
    margin-bottom: 0;
  }
}

.diamond-access-icon {
  float: right;
  height: 1.875em;
  width: 1.875em;
  color: #000;
}

@include media-breakpoint-up(md) {
  .publication-details-container {
    > .row {
      padding-right: 2.75rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .publication-details-container {
    > .row {
      padding: 0 15px;
    }
  }

  .publication-details-sidebar {
    margin-left: 15px;
    margin-right: 15px;
  }
}

/* Library */

.library-list-header {
  margin-top: 1rem;
}

/* Resources & database journal list */

div.container.resources-container {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.resources-list {
  width: 100%;
}

.database-journal-list-container {
  width: calc(100% + 30px);
}

.resources-list,
.database-journal-list-container {
  .list-group {
    width: 100%;
  }

  .list-group-item {
    padding: 0.5rem 1rem;
    border-radius: 0;
  }

  .list-group-item + .list-group-item {
    margin-top: 1rem;
  }

  .publisher-name-logo-container {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    vertical-align: top;
    text-align: center;
  }

  .publishing-company-logo {
    max-width: 2.5rem;
    max-height: 2.5rem;
  }

  h2, h3 {
    margin-bottom: 0;
    padding: 0.3125rem 0;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.86px;
  }

  .publisher-name-logo-container + .publisher-name-header-container {
    display: inline-block;
    margin-top: 0.25rem;
    margin-left: 1rem;
    padding: 0;
    width: calc(100% - 3.5rem);
  }
}

/* Tabbed details pages */

@mixin nav-tabs-inline() {
  @include nav-tabs;
  margin-left: 0.5rem;
  border-bottom: 0;

  &, .nav-item, .nav-link {
    display: inline;
  }

  a {
    color: $blue-link-color;

    &:hover, &:active, &:focus {
      color: $blue-link-hover-color;
    }
  }
}

.tabbed-details-name-container {
  @include make-container();
  @include make-container-max-widths();

  padding: 0;

  .tabbed-details-name-logo-container {
    display: inline-block;
    margin: 0.75rem 0;
    width: 5rem;
    height: 5rem;
    vertical-align: top;
    text-align: center;

    .publishing-company-logo {
      max-width: 5rem;
      max-height: 5rem;
    }
  }

  .tabbed-details-name-logo-container + .tabbed-details-name-header-container {
    display: inline-block;
    margin-top: 1rem;
    margin-left: 1rem;
    width: calc(100% - 6rem);
  }

  &.tabbed-details-sidebar-present .tabbed-details-name-logo-container {
    img.search-result-cover-thumbnail {
      max-width: 5rem;
      max-height: 5rem;
    }

    & + .tabbed-details-name-header-container {
      margin-top: 0;
    }
  }

  .tabbed-details-name-header {
    width: 100%;
    margin-top: 0.8125rem;
    padding-bottom: 0.0625rem;
    @include card-header;

    .nav-tabs {
      @include nav-tabs-inline();
    }
  }

  h1.tabbed-details-name {
    margin-top: 0.25rem;
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.77px;
  }

  .tabbed-details-company-logo {
    max-width: 5rem;
    max-height: 5rem;
  }

  .tabbed-details-sidebar {
    padding: 1rem 0;
  }

  @include media-breakpoint-down(xs) {
    .tabbed-details-name-logo-container {
      width: 100%;
    }

    .tabbed-details-name-logo-container + .tabbed-details-name-header-container {
      margin-left: 0;
      width: 100%
    }
  }
}

.tabbed-details-tabs-container {

  .tabbed-details-tab-nav-container {
    background: linear-gradient(90deg, $grey-background-color 0%, $grey-background-color 100%) no-repeat;
  }

  nav, .nav, .tab-content {
    @include make-container();
    @include make-container-max-widths();
    padding: 0;
  }

  nav, .nav {
    padding: 0.5em 0 0 0;
  }

  .nav-tabs {
    @include nav-tabs;

    a.nav-link {
      padding: 0.5rem 0.75rem;
      letter-spacing: 0.32px;
      color: $black-link-color;

      &:hover, &:active, &:focus {
        color: $black-link-hover-color;
        border: none;
      }

      &.active {
        background: #fff;
        border-bottom-width: 5px;
        font-weight: bold;

        &:hover, &:active, &:focus {
          background: #fff;
          border-bottom-width: 5px;
        }
      }
    }
  }

  .tab-pane > .container {
    padding: 0;

    .list-group-item {
      border-radius: 0;

      h3 {
        margin-bottom: 0;
        font-size: 1.5rem;
        font-family: $font-PTSerif;
      }

      .publications-list-authors {
        margin-top: 0.5em;
      }

      ul {
        margin: 0;
        padding: 0;
        font-weight: 500;
        display: inline-block;

        li {
          display: inline;
        }

        li + li {
          margin-left: 1rem;
        }
      }
    }

    .list-group-item + .list-group-item {
      margin-top: 1rem;
    }

    h3 {
        font-size: 1.25rem;
    }
  }

  .section {
    margin: 0;
    border: none;
    border-radius: 0;
  }

  .card-body {
    padding: 0;
  }

  .tabbed-details-tab-basic-info h3, .tabbed-details-name-header {
    width: 100%;
    margin-top: 0.8125rem;
    padding-bottom: 0.125rem;
    @include card-header;
  }

  .tabbed-details-tab-basic-info {
    h3:first-child {
      margin-top: 0;
    }

    .tabbed-details-sidebar h3:first-child {
      margin-top: 0;
    }
  }

  .row + .row {
    margin-top: 1rem;
  }

  .ql-editor {
    padding: 0;
  }
}

.tabbed-details-tabs-container, .publication-details-container {
  .accordion {
    border: 1px solid $grey-border-color;

    + .accordion {
      margin-top: 1rem;
    }

    .section {
      margin: 0;
      border: none;
      border-radius: 0;
    }

    .card-body {
      padding: 0;
    }

    .expand-button {
      padding: 1.125rem 1rem 1rem 1rem;
      background: #F6F6F6;
      letter-spacing: 0.5px;
      text-align: left;
      border-radius: 0;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;

      &:focus, &:hover, &:active {
        border-left-color: #000;
        border-right-color: #000;
        border-top-color: #000;
      }

      &::after {
        float: right;
      }
      &[aria-expanded="true"]::after {
        content: "▴";
      }
      &[aria-expanded="false"]::after {
        content: "▾";
      }
    }

    .card-body {
      margin-bottom: 1rem;
    }

    .nav-tabs {
      @include nav-tabs-inline();
      font-weight: bold;
      margin-left: 0;
    }
    .tab-content {
      margin-top: 10px;
    }
  }
}

#journalAdditionalTitles {
  > span + span::before {
    content: ", ";
  }
}

#journalExternalIdentifiers {
  > span + span::before {
    content: ", ";
  }
}

#seriesExternalIdentifiers {
  > span + span::before {
    content: ", ";
  }
}

#journalAdditionalDescriptions {
  margin-top: 1rem;
}

.journal-external-id-text {
  font-weight: bold;
}

.series-external-id-text {
  font-weight: bold;
}

#journalMainScores, #journalAdditionalScores {
  max-width: 30em;
  float: left;
  margin-top: 0;
}

.journal-main-score-type, .journal-additional-score-type {
  flex-grow: 1;
  flex-basis: min-content;
  flex-shrink: 1;
}

.journal-main-score-text, .journal-additional-score-text {
  flex-shrink: 0;
  flex-basis: min-content;
  flex-grow: 0;
}

.journal-main-score-type, .journal-main-score-text,
.journal-additional-score-type, .journal-additional-score-text {
  padding: 0;
  border-bottom: 1px solid $grey-border-color;
}

.journal-main-score-text, .journal-additional-score-text {
  text-align: right;
  font-weight: bold;
}

.journal-additional-description-entry + .journal-additional-description-entry {
  margin-top: 0.5rem;
}

.tabbed-details-sidebar-present {
  display: inline-block;
  width: calc(100% - 286px);
  vertical-align: top;
}

.tabbed-details-sidebar {
  display: inline-block;
  margin-left: 24px;
  width: 262px;
  vertical-align: top;

  .linked-journal-link-container, .linked-journals-tab {
    margin-top: 0.5rem;
  }

  .linked-journals-tab {
    margin-top: 1rem;
  }

  .latest-issue, .latest-book {
    font-family: $font-PTSerif;

    .latest-issue-cover-container, .latest-book-cover-container {
      width: 262px;
      max-height: 262px;

      img {
        max-width: 262px;
        max-height: 262px;
      }
    }

    .latest-book-title, .latest-issue-journal, .linked-journal-link-text {
      margin: 1rem 0 0 0;
      letter-spacing: 0.23px;
    }
  }

  a {
    color: $black-link-color;

    &:hover, &:active, &:focus {
      color: $black-link-hover-color;
    }
  }
}

.tabbed-details-tab-basic-info .scientificField {
  .scientificFieldHeader {
    @include card-header();
    width: 100%;
    padding-bottom: .25rem;
    display: block;
  }

  ul {
    padding-left: 0;
  }
}

@include media-breakpoint-down(sm) {
  .tabbed-details-sidebar-present, .tabbed-details-sidebar {
    display: block;
    width: 100%;
  }

  .tabbed-details-sidebar {
    margin: 0;
    padding: 1rem 0;
  }
}

@include media-breakpoint-down(xs) {
  .tabbed-details-name-container,
  .tabbed-details-tabs-container .tab-content,
  .tabbed-details-tabs-container nav {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .tabbed-details-tab-basic-info {
    margin-left: 0;
    margin-right: 0;
  }

  .tabbed-details-tabs-container .nav-tabs a.nav-link {
    width: 100%;
    margin: 0 1rem;
  }

  .journal-main-description-text.col {
    padding: 0;
  }

  .issue-articles-container {
    padding-left: calc(1rem + 15px);
    padding-right: calc(1rem + 15px);
  }
}

/* Issue list */

$issue-list-thumbnail-size: 100px;

.list-group.issue-list {
  margin-top: -1rem;
  display: block;

  .list-group-item {
    margin-top: 1rem;
    display: inline-block;
    vertical-align: top;

    .issue-list-thumbnail {
      display: inline-block;
      vertical-align: top;
      width: $issue-list-thumbnail-size;
      height: $issue-list-thumbnail-size;
      text-align: center;

      img {
        vertical-align: top;
        max-width: $issue-list-thumbnail-size;
        max-height: $issue-list-thumbnail-size;
      }
    }

    .issue-list-info {
      display: inline-block;
      width: calc(100% - 1rem - #{$issue-list-thumbnail-size});
      margin-left: 1rem;
      word-break: break-word;

      h3.list-issue-title {
        font-family: $font-Roboto;
        letter-spacing: 0.77px
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .issue-list .list-group-item {
    width: 100%;
  }
}

@include media-breakpoint-only(md) {
  .issue-list .list-group-item {
    width: calc(50% - 0.5rem);

    &:nth-of-type(2n+1) {
      margin-right: 1rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .issue-list .list-group-item {
    width: calc(33.333% - 0.667rem);

    &:not(:nth-of-type(3n)) {
      margin-right: 1rem;
    }
  }
}

.issue-list-year-container {
  margin-bottom: 1rem;
  text-align: right;

  .issue-list-year-select {
    min-width: 15rem;
    display: inline-block;
    margin-left: 10px;
    text-align: left;
  }
}


/* Issue details */

.page-range, .article-number {
  float: right;
  padding: 0;
  font-size: 0.75rem;
  font-weight: bold;
}

h1.issue-name {
  margin-top: 0.5rem;
  font-size: 1.875rem;
}

.article-list-container {
  .list-group-item {
    border-radius: 0;
    word-break: break-word;

    h3 {
      margin-bottom: 0;
      font-size: 1.5rem;
      font-family: $font-PTSerif;
    }

    ul {
      margin: 0.5rem 0 0 0;
      padding: 0;
      font-weight: 500;
      display: inline-block;

      li {
        display: inline;
      }

      li + li {
        margin-left: 1rem;
      }
    }

    .page-range, .article-number {
      margin: 0.25rem 0 0.25rem 0.5rem;
    }
  }

  .list-group-item + .list-group-item {
    margin-top: 1rem;
  }

  border-radius: 0;
  width: 100%;
}

/* Join publishers page */

.join-publishers-button-container {
  padding: 0.5rem 0.9375rem;
  border: 1px solid #dddbda;
  border-radius: 4px;
  font-weight: bold;
  line-height: 2rem;
  letter-spacing: 0.5px;

  button {
    float: right;
  }

  & + & {
    margin-top: 1rem;
  }
}

/* Sitemap page */

ul.sitemap-links {
  a {
    text-decoration: underline;
  }

  li > ul {
    padding-left: 1.5rem;
  }
}

/* Share modal */

a[class*="share-button-"],
span[class*="share-button-"] {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  transition: opacity 0.2s;

  &:active, &:hover, &:focus {
    color: #FFF;
    opacity: 0.8;
    transition: opacity 0.2s;
  }
}

.share-button-mail {
  color: #FFF;
  background: #55acee;
}

.share-button-link {
  cursor: pointer;
  color: #FFF;
  background: #95d03a;
}

.share-button-x {
  color: #FFF;
  background: #000;
}

.share-button-linkedin {
  color: #FFF;
  background: #0a66c2;
}

.share-button-fb {
  color: #FFF;
  background: #1877F2;
}

.share-button-reddit {
  color: #FFF;
  background: #FF4500;
}

.share-modal-link-label {
  margin-top: 2em;
  margin-bottom: 0.5em;
}

.share-modal-link-input {
  margin-bottom: 1em;

  input {
    width: 100%;
  }
}

/* Popups */

.modal-header {
  border-bottom: none;

  .close {
    font-size: 2rem;
    font-weight: normal;
  }
}

.modal-body {
  padding-bottom: 0 1rem;

  .container {
    margin-top: 0.5rem;
  }

  .info-item.row {
    padding-bottom: 0.5rem;
  }
}

.modal-footer {
  > * {
    margin: 0;
  }

  border-top: 0;
}

.modal-citation-header {
  width: 100%;
  @include card-header;
}

.contributor-info-modal-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.modal-panel {
  margin: 5px -10px;
  padding-top: 0.5rem;
  border: 1px solid $grey-border-color;
  background: $grey-background-color;
  border-radius: 4px;

  &.contributor-info {
    h6 {
      margin-bottom: 0;
      font-weight: normal;
    }

    > div + div {
      margin-top: 1.5rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  /* "Fix" cutoff modal windows in mobile Firefox while adress bar is not visible */
  .modal-dialog {
    margin-bottom: 4.75rem;
  }
}

@include media-breakpoint-down(md) {
  /* Wrap math formulas on small displays */
  mjx-math {
    white-space: normal !important;
  }
}
